"use client";

import Transition from "../../transition/components/Transition/Transition";
import {
  animatePageIn,
  popStateIn,
} from "../../transition/TransitionAnimations";
import { useCallback, useEffect } from "react";
import { useRefStore } from "../../store/ref-store";
import { usePathname } from "next/navigation";

export default function Template({ children }: { children: React.ReactNode }) {
  const { websiteLoading, firstRender, fromPopState, counterTemp } =
    useRefStore();

  const path = usePathname();

  const handleAnimation = () => {
    if (!counterTemp) {
      if (!fromPopState) {
        animatePageIn();
      } else {
        popStateIn();
      }
      if (firstRender) {
        useRefStore.setState({ firstRender: false });
      }
    }
  };

  useEffect(() => {
    handleAnimation();
  }, [websiteLoading, counterTemp, path]);

  return (
    <div>
      <Transition />
      <section
        className="generalPagesWrap"
        style={{
          overflowY: "hidden",
          maxHeight: "100vh",
          maxWidth: "100vw",
        }}
      >
        {children}
      </section>
    </div>
  );
}
